import { auth, db } from "../services/firebase";
import { collection, query, where, getDocs } from 'firebase/firestore';
import React, { useEffect, useState, useCallback } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import logo from './logo_tilla_white.svg';

import "../App.css";

const Home = ({ user }) => {

  const [showModal, setShowModal] = useState(false);
  const [setupData, setSetupData] = useState({ callrail: {}, ga4: {} });
  const [isAdmin, setIsAdmin] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [idToken, setIdToken] = useState(null);

  useEffect(() => {
    if (user) {
      user.getIdToken(true).then((token) => {
        setIdToken(token);
      });
    }
  }, [user]);
  
  useEffect(() => { 
    fetchUserRole();
  }, []); // Add dependency array for fetchUserRole function
  
  const customizeIframe = useCallback(async () => {
    
    const paramCollection = collection(db, "tilladelse-parameters");
    
    const q = query(paramCollection, where("email","==",user.email))
    
    const querySnapshot = await getDocs(q);
    
    var paramId = ""
    
    querySnapshot.forEach((doc) => {
      paramId = doc.data().parameter;
    });
    var iframe = document.querySelector('#portal');
    var tempSrc = '';
    if (paramId[0].includes("https://lookerstudio.google.com/embed/reporting/")) {
      tempSrc = paramId;
    } else {
      tempSrc = `https://lookerstudio.google.com/embed/reporting/22e892c2-93d8-4419-9bda-5d31a36c25ac/page/p_ndtmfpytuc?params=%7B"df97":"include%25EE%2580%25800%25EE%2580%2580EQ%25EE%2580%2580${paramId.join('%25EE%2580%2580')}"%7D`;
    }
    // console.log(tempSrc);
    iframe.setAttribute('src',tempSrc);
  }, [user.email]);
  
  useEffect(() => { 
    customizeIframe();
  }, [customizeIframe]); // Add dependency array for customizeIframe function

  const fetchUserRole = async () => {
    const paramCollection = collection(db, "tilladelse-parameters");
    const q = query(paramCollection, where("email", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setIsAdmin(doc.data().role === 'admin');
    });
  };

  const fetchSheetData = async () => {
    const response = await fetch("https://script.google.com/macros/s/AKfycbzfniQGwfSj_p8AgINhceh-GiratTNyRwnvoVPazl_n56z2hLq2RvNi0_xCpj2H9xTdiA/exec");
    const data = await response.json();
    setSetupData(data);
  };

  const handleSettingsClick = () => {
    fetchSheetData();
    setShowModal(true);
  };

  return (
    <div className="container-fluid" style={{ marginTop: "0%", marginBottom: "0.5%" }}>
      <div className="row">
        <div className="col" style={{margin: "1%", marginLeft: "0.25%"}}>
        <img src={logo} width={"120px"} style={{float: "left", marginRight: "24px"}} alt="Logo"></img>
          <div style={{marginTop: "4px", top: "50%", position: "relative", transform: "translateY(-50%)"}}>
            <span class="mb-3 mb-md-0 text-muted"> | Dashboard Portal</span>
          </div>
        </div>
        <div className="col" style={{margin: "1%", marginRight: "0%"}}>
          <Card.Body style={{paddingRight: "0px", textAlign: "end"}}>
            {/* <Card.Title>Current User Email :</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {user.displayName}
            </Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">
              {user.email}
            </Card.Subtitle> */}
            {/* <img src={user.photoURL} alt="" /> */}
            <Button className="me-md-2" style={{margin: '0%', marginLeft: '0%'}} variant="primary" size="sm" type="submit" onClick={() => customizeIframe()}>
            <i class="bi bi-arrow-clockwise"></i>
              {/* {" Load my dashboard"} */}
            </Button>
            {isAdmin && (
              <Button className="me-md-2" variant="secondary" size="sm" type="button" onClick={handleSettingsClick}>
                <i class="bi bi-gear"></i>
              </Button>
            )}
            <Button style={{marginLeft: '0%'}} variant="danger" size="sm" type="submit" onClick={() => auth.signOut()}>
            <i class="bi bi-box-arrow-left"></i>
              {/* Sign Out */}
            </Button>
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Callrail Config Details</h5>
                <div style={{ marginBottom: "32px" }}>
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Fields:</label>
                  <input type="text" value={setupData.callrail.fields} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Date Range:</label>
                  <input type="text" value={setupData.callrail.dateRange} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Frequency:</label>
                  <input type="text" value={setupData.callrail.frequency} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>GCP Project ID:</label>
                  <input type="text" value={setupData.callrail.gcpProjectId} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>BQ Dataset ID:</label>
                  <input type="text" value={setupData.callrail.bqDatasetId} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Last Run Time:</label>
                  <input type="text" value={new Date(setupData.callrail.lastRunTime).toLocaleString()} readOnly className="form-control" />
                </div>
                <h5>GA4 Config Details</h5>
                <div>
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Metrics:</label>
                  <input type="text" value={setupData.ga4.metrics} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Dimensions:</label>
                  <input type="text" value={setupData.ga4.dimensions} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Frequency:</label>
                  <input type="text" value={setupData.ga4.frequency} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>GCP Project ID:</label>
                  <input type="text" value={setupData.ga4.gcpProjectId} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>BQ Dataset ID:</label>
                  <input type="text" value={setupData.ga4.bqDatasetId} readOnly className="form-control" />
                  <label style={{ fontSize: "0.8rem", color: "#666" }}>Last Run Time:</label>
                  <input type="text" value={new Date(setupData.ga4.lastRunTime).toLocaleString()} readOnly className="form-control" />
                </div>
              </Modal.Body>
            </Modal>
          </Card.Body>
        </div>
      </div>
      <div className="row">
        <iframe title="Dashboard Portal" id="portal" width="1600" height="2200"></iframe>
      </div>
      {(user.email === "ssa-demo@tilladelse.com") && (
        <>
          <Modal show={showChat} onHide={() => setShowChat(false)}>
            <Modal.Dialog style={{position: 'fixed', bottom: '0px', right: '20px', maxWidth: '800px', borderRadius: '5px'}}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body style={{height: '600px', width: '450px', padding: 0, borderRadius: '5px'}}>
                <iframe title="Chat Portal" style={{width: "100%", height: "100%"}} src={`https://chat-widget.text2bi.com/?token=${idToken}`}></iframe>
                {/* <iframe title="Chat Portal" style={{width: "100%", height: "100%"}} src={`http://localhost:3000/?token=${idToken}`}></iframe> */}
              </Modal.Body>
            </Modal.Dialog>
          </Modal>
          <Button 
            onClick={() => setShowChat(true)} 
            style={{
              backgroundColor: '#3BA37F',
              borderColor: '#3BA37F',
              color: 'white',
              borderRadius: '50%', 
              width: '60px', 
              height: '60px', 
              position: 'fixed', 
              bottom: '20px', 
              right: '20px', 
              fontSize: '24px'
            }}
          >
            <i class="bi bi-chat-dots"></i>
          </Button>
        </>
      )}
      <div class="container" style={{marginTop: "1.5%"}}>
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-6 border-top">
        <div class="col-md-4 d-flex align-items-center">
          <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
          </a>
          <span class="mb-3 mb-md-0 text-muted">&copy; Copyright 2024 Tilladelse</span>
        </div>
    
        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li class="ms-3"><a class="text-muted" href="https://tilladelse.com"><i class="bi bi-link-45deg"></i></a></li>
          {/* <li class="ms-3"><a class="text-muted" href="https://twitter.com/DataStudioLabs"><i class="bi bi-twitter"></i></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.facebook.com/DataStudioLabs"><i class="bi bi-facebook"></i></a></li>
          <li class="ms-3"><a class="text-muted" href="https://www.instagram.com/datastudiolabs"><i class="bi bi-instagram"></i></a></li> */}
        </ul>
      </footer>
      </div>
    </div>
  );
};

export default Home;
